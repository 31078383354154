var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('nav',{staticClass:"header-menu"},[_c('ul',{staticClass:"header-menu__nav"},[_c('li',{staticClass:"header-menu_button"},[_c('RouterLink',{staticClass:"header-menu_link header-menu_link__size",attrs:{"title":_vm.$t('page.home.projects'),"to":{
                    name: 'prescription.home',
                    params: {
                        cityId: _vm.$route.params.cityId,
                    },
                },"header-link":""}},[_vm._v(" "+_vm._s(_vm.$t('page.home.projects'))+" ")])],1),_c('li',{staticClass:"header-menu_button"},[_c('RouterLink',{staticClass:"header-menu_link header-menu_link__size",attrs:{"title":_vm.$t('titles.snips'),"to":{
                    name: 'prescription.requirements',
                    params: {
                        cityId: _vm.$route.params.cityId,
                    },
                },"header-link":""}},[_vm._v(" "+_vm._s(_vm.$t('page.home.snip'))+" ")])],1),_c('li',{staticClass:"header-menu_button"},[_c('RouterLink',{staticClass:"header-menu_link header-menu_link__size",attrs:{"title":_vm.$t('titles.catalog'),"to":{
                    name: 'prescription.list',
                    params: {
                        cityId: _vm.$route.params.cityId,
                    },
                },"header-link":""}},[_vm._v(" "+_vm._s(_vm.$t('titles.catalog'))+" ")])],1),(_vm.hasCreateDrawingAccess)?_c('li',{staticClass:"header-menu_button"},[_c('RouterLink',{staticClass:"header-menu_link header-menu_link__size",attrs:{"title":_vm.$t('titles.uploadScheme'),"to":{
                    name: 'prescription.scheme',
                    params: {
                        cityId: _vm.$route.params.cityId,
                    },
                },"header-link":""}},[_vm._v(" "+_vm._s(_vm.$t('titles.uploadScheme'))+" ")])],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }